import React, { useMemo } from 'react'
import styled from 'styled-components'

import Button from 'components/shared/Button'
import { Text } from 'components/shared/Typography'
import Icon from 'components/shared/Icon'
import { Flex } from 'components/shared/Grid'

export type DataItem = {
  icon: string
  label?: string
  value: string
}

const Link = styled.a`
  display: block;
  text-decoration: none;
  width: 100%;
  &:hover {
    ${Button} {
      background: ${({ theme }) => theme.colors.primary100};
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.75rem 0.75rem 0;
  font-style: normal;
  width: 100%;
  & > ${Button} {
    pointer-events: none;
    margin-right: 0.5rem;
    ${({ theme }) => theme.media.lg.max} {
      min-width: 50px;
      max-width: 50px;
      min-height: 50px;
      max-height: 50px;
    }
  }
  ${Text} {
    white-space: nowrap;
  }
`

const LINK_TYPES = [
  {
    prefix: 'mailto:',
    regex: /^\S+@\S+\.\S+$/,
  },
  {
    prefix: 'tel:',
    regex: /^\+(?:[0-9] ?){6,14}[0-9]$/,
  },
]

const ContactDataItem: React.FC<DataItem> = ({ icon, label, value }) => {
  const linkType = LINK_TYPES.find(({ regex }) => regex.test(value))

  const dataDOM = useMemo(
    () => (
      <Wrapper>
        <Button as="div" white50 round roundSize="66px">
          <Icon src={icon} />
        </Button>
        <Flex direction="column">
          {label && (
            <Text
              size={14}
              line="1.2"
              margin="0.25rem"
              themecolor="primary"
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
          <Text
            size={14}
            line="1.2"
            themecolor="darkgray"
            dangerouslySetInnerHTML={{ __html: value }}
          />
        </Flex>
      </Wrapper>
    ),
    [label, value, icon]
  )

  if (linkType) {
    return (
      <Link as="a" href={`${linkType.prefix + value}`}>
        {dataDOM}
      </Link>
    )
  }

  return dataDOM
}

export default ContactDataItem
