import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/Container'
import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'
import { Heading, Text } from 'components/shared/Typography'
import { Flex } from 'components/shared/Grid'

import ContactDataItem, {
  DataItem,
} from 'components/layout/Sections/Contact/ContactDataItem'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  title: string
  description: string
  icon: string
  list?: DataItem[]
}

const Section = styled.section`
  position: relative;
  padding: 1rem 0 2rem;
  ${Button} {
    pointer-events: none;
    margin-right: 1rem;
    ${({ theme }) => theme.media.lg.max} {
      min-width: 50px;
      max-width: 50px;
      min-height: 50px;
      max-height: 50px;
      margin-right: 0.5rem;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  ${({ theme }) => theme.media.lg.max} {
    flex-direction: column;
  }
  & > ${Flex} {
    width: 100%;
  }
`

const Address = styled.address`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  ${({ theme }) => theme.media.lg.max} {
    margin: 1rem 0 -1rem;
  }
  ${({ theme }) => theme.media.sm.max} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  & > div,
  & > a {
    max-width: max-content;
  }
`

const Spacer = styled.span`
  display: block;
  min-width: 1px;
  background: ${({ theme }) => theme.colors.primary};
  margin: -2rem 2rem 1rem;
  ${({ theme }) => theme.media.lg.max} {
    display: none;
  }
`

const StyledText = styled(Text)`
  p {
    margin: 0;
  }
  ${({ theme }) => theme.media.lg.max} {
    font-size: 1rem;
    line-height: 1.4;
  }
  ${({ theme }) => theme.media.sm.max} {
    margin-top: 0.75rem;
  }
`

const ContactIntro: React.FC<Props> = ({ title, icon, description, list }) => {
  const { sm } = useBreakpoint()

  return (
    <Section>
      <Container>
        <Wrapper>
          <Flex direction="column">
            <Flex alignItems={sm ? 'flex-start' : 'center'}>
              <Button as="div" white50 round roundSize="66px">
                <Icon src={icon} />
              </Button>
              <div>
                <Heading
                  as="h3"
                  line="1.2"
                  margin="0"
                  size={30}
                  weight={500}
                  sizeDiff={0.5}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                {sm && (
                  <StyledText
                    as="div"
                    size={17}
                    line="1.2"
                    themecolor="darkgray"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
              </div>
            </Flex>
            {!sm && (
              <StyledText
                as="div"
                size={17}
                line="1.2"
                themecolor="darkgray"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </Flex>
          {list && list.length > 0 && (
            <>
              <Spacer />
              <Address>
                {list.map((item) => (
                  <ContactDataItem
                    key={item.value}
                    icon={item.icon}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </Address>
            </>
          )}
        </Wrapper>
      </Container>
    </Section>
  )
}

export default ContactIntro
