import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Header from 'components/layout/Headers/Secondary'
import HeaderFilters from 'components/layout/Headers/Secondary/Filters'
import ContactIntro from 'components/layout/Sections/Contact/Intro'
import Map from 'components/layout/Maps'

import { CONTACT_LINKS } from 'constants/links'

import type { ContactHeadquartersPageQuery } from 'types/graphql'
import type { InvestmentPin } from 'components/layout/Maps/InvestmentPin'

const ContactHeadquarters: React.FC<PageProps<ContactHeadquartersPageQuery>> =
  ({ data }) => {
    const { page } = data

    const PAGE = page?.pageCompanyHeadquarters
    const PAGE_SEO = page?.seo

    if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

    const HEADQUARTERS = PAGE.companyHeadquartersPageS2List![0]!

    const MAP_INVESTMENTS: InvestmentPin[] = useMemo(
      () =>
        PAGE.companyHeadquartersPageS3MapPushpin?.map((pin) => ({
          name: pin?.companyHeadquartersPageS3MapPushpinTitle!,
          image:
            pin?.companyHeadquartersPageS3MapPushpinImg?.localFile
              ?.childImageSharp?.gatsbyImageData!,
          lat: Number(pin?.companyHeadquartersPageS3MapPushpinLat!),
          lng: Number(pin?.companyHeadquartersPageS3MapPushpinLng!),
        })) || [],
      []
    )

    return (
      <Layout>
        <SEO seo={PAGE_SEO} />
        <Breadcrumbs crumbs={[{ name: 'Kontakt' }]} />
        <Header
          title={PAGE.companyHeadquartersPageS1Title!}
          image={{
            src: PAGE.companyHeadquartersPageS1Img?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.companyHeadquartersPageS1Img?.altText!,
          }}
        />
        <main>
          <HeaderFilters options={CONTACT_LINKS} />
          <ContactIntro
            title={HEADQUARTERS.companyHeadquartersPageS2ListElementTitle!}
            description={
              HEADQUARTERS.companyHeadquartersPageS2ListElementDescription!
            }
            icon={
              HEADQUARTERS.companyHeadquartersPageS2ListElementImg?.localFile
                ?.publicURL!
            }
            list={HEADQUARTERS.companyHeadquartersPageS2ListElementContactDetails?.map(
              (detail) => ({
                icon: detail
                  ?.companyHeadquartersPageS2ListElementContactDetailsImg
                  ?.localFile?.publicURL!,
                label:
                  detail?.companyHeadquartersPageS2ListElementContactDetailsTitle!,
                value:
                  detail?.companyHeadquartersPageS2ListElementContactDetailsDescription!,
              })
            )}
          />
          {MAP_INVESTMENTS.length > 0 && (
            <Map
              investments={MAP_INVESTMENTS}
              center={{
                lat: MAP_INVESTMENTS[0].lat,
                lng: MAP_INVESTMENTS[0].lng,
              }}
            />
          )}
        </main>
      </Layout>
    )
  }

export default ContactHeadquarters

export const query = graphql`
  query ContactHeadquartersPage($locale: String) {
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/siedziba-firmy/" }
    ) {
      seo {
        ...WpSEO
      }
      pageCompanyHeadquarters {
        companyHeadquartersPageS1Title
        companyHeadquartersPageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        companyHeadquartersPageS2List {
          companyHeadquartersPageS2ListElementTitle
          companyHeadquartersPageS2ListElementDescription
          companyHeadquartersPageS2ListElementImg {
            localFile {
              publicURL
            }
          }
          companyHeadquartersPageS2ListElementContactDetails {
            companyHeadquartersPageS2ListElementContactDetailsTitle
            companyHeadquartersPageS2ListElementContactDetailsDescription
            companyHeadquartersPageS2ListElementContactDetailsImg {
              localFile {
                publicURL
              }
            }
          }
        }
        companyHeadquartersPageS3MapPushpin {
          companyHeadquartersPageS3MapPushpinTitle
          companyHeadquartersPageS3MapPushpinLng
          companyHeadquartersPageS3MapPushpinLat
          companyHeadquartersPageS3MapPushpinImg {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
